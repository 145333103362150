<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog_from_input"
      width="950"
      @click:outside="close()"
      @keydown.esc="close()"
      scrollable
    >
      <v-card
      tile
      >

        <v-card-title
          class="custom-modal-title"
          primary-title
        >
          {{edit_data ? 'Edit' : 'Create'}} Expense
            <v-spacer></v-spacer>
          <span @click="close" style="cursor:pointer">x</span>
        </v-card-title>

        <v-card-text>
          <v-container>
               <v-form
                    ref="form"
                    v-model="valid"
                    :lazy-validation="lazy"
                    >

                    <v-menu
                      v-model="menu2"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="date"
                          label="Ducument Date"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="date"
                        @input="menu2 = false"
                      ></v-date-picker>
                    </v-menu>
                    <v-text-field
                    v-model="total_format"
                    label="Total"
                    :rules="priceRules"
                    required
                    ></v-text-field>
                    <v-textarea
                      v-model="note"
                      label="Note"
                    ></v-textarea>

               </v-form>

          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            tile
            class="custom-modal-title"
            @click="close"
          >
            Cancel
          </v-btn>

           <v-btn
            color="success"
            tile
            :disabled="!valid"
            @click="edit_data ? update() : save()"
            :loading="loading"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import MixinGlobal from '../../../mixin/index'
export default {
  mixins:[MixinGlobal],
  methods: {
    send_data() {
      const data = new URLSearchParams()
      data.append('note', this.note)
      data.append('doc_no', "")
      data.append('branch_id', this.user.karyawan.branch_id)

      data.append('doc_type', "EXPENSE")
      data.append('doc_date', this.date)
      data.append('total', this.total)
      data.append('entry_by', this.user.fullname)
      data.append('company_id', this.user.company_id)
      return data
    },
    async save () {
      this.loading = true
      const data = this.send_data()
      await this.axios.post('v1/transactions/expense/create', data)
        .then(async (ress) => {
          this.setSnakbar({
            status: true,
            pesan: 'Success submit data',
            color_snakbar: 'success'
          })
          await this.go('expense','expenses')
          this.close()
        })
        .catch((err) => console.log(err.response))
      this.loading = false
    },

    async update() {
      this.loading = true
      const data = this.send_data()
      await this.axios.put('v1/transactions/expense/update/' + this.edit_data.id, data)
        .then(async (ress) => {
          this.setSnakbar({
            status: true,
            pesan: 'Success update data',
            color_snakbar: 'success'
          })
          await this.go('expense','expenses')
          this.close()
        })
        .catch((err) => console.log(err.response))
      this.loading = false
    },

    close () {
      this.reset()
      this.$emit('close')
    },
    reset () {
      this.note = ''
      this.doc_date = '',
      this.total = 0
      this.date = new Date().toISOString().substr(0, 10)
    }
  },

  watch: {

  },

  data: () => ({
    valid: true,
    lazy: false,
    loading:false,
    name: '',
    dialog:true,
    note: '',
    branch_id:'',
    total:0,
    priceRules: [
      v => !!v || 'Required field',
      v => /^[0-9,]+$/.test(v) || 'Format Price (1,000,xxx)',
    ],
    date: new Date().toISOString().substr(0, 10),
    menu: false,
    modal: false,
    menu2: false,
    nameRules: [
      v => !!v || 'Tidak Boleh Kosong'
    ],
    foto: null

  }),

  computed: {
    ...mapGetters({
      branchs:'data/branchs',
    }),
    total_format:{
      get(){
          return this.formatAsCurrency(this.total, 0)
      },
      set(newValue){
          this.total= Number(newValue.replace(/[^0-9\.]/g, ''))
      }
    }
  },

  async created () {

    if (this.edit_data) {
      this.note = this.edit_data.note,
      this.branch_id = this.edit_data.branch_id,
      this.total = parseInt(this.edit_data.total)
      this.date = new Date(this.edit_data.doc_date).toISOString().substr(0, 10)
    }
            await this.get_data_master('branch','branchs')

  }
}
</script>
