import Progress from '../../../components/external/Progress'
import formInput from './components/form'
import dialogDelete from '../../../components/external/DialogDelete'
import GlobalMixins from '../../mixin/index.js'
export default {
  mixins:[GlobalMixins],
  data () {
    return {
      user_edit: null,
      from_menu:false,
      to_menu:false,
      from:new Date().toISOString().substr(0, 10),
      to:new Date().toISOString().substr(0, 10),
      branch_id:'',
      from_menu:false,
      to_menu:false,
      branch:[],
      params:'',
      headers: [

        {
          text: 'Date',
          value: 'doc_date'
        },
        {
          text: 'Total',
          value: 'total',
          align: 'right',
        },
         {
          text: 'Note',
          value: 'note'
        },
        {
          text: 'Actions',
          sortable: false,
          value: 'action'
        }
      ]
    }
  },
  components: {
    Progress,
    formInput,
    dialogDelete
  },
  methods: {
    activerow: function (item, b, event) {
      console.log(event)
      this.$refs.menu.open(0, 0)
    },
    async deleteAction() {
      const data = new URLSearchParams()
      data.append('is_deleted','Y')
      data.append('update_by',this.user.fullname)
      await this.axios.put('v1/expense/delete/' + this.data_delete.id, data)
        .then(async (ress) => {
          this.setSnakbar({
            status: true,
            pesan: 'Success Delete expenses',
            color_snakbar: 'success'
          })
          await this.go('expense','expenses',`branch_id=${branch_id}&from=${from}&to=${to}`)
          this.close()
        })
        .catch((err) => console.log(err.response))
    },
    // async go (table,store) {
    //   await this.axios.get('/v1/'+ table +'?company_id=' + this.user.company_id, {
    //     headers: {
    //       Authorization: 'Bearer ' + this.user.token
    //     }
    //   })
    //     .then(async (ress) => {
    //       await this.setData({ data: ress.data.data, table_name: store })
    //     })
    //     .catch(err => console.log(err))
    //   this.loading = false
    // },
    // close () {
    //   this.dialog_delete = false
    //   this.user_edit = null
    //   this.dialog_form = false
    // }
  },
}
